<template>
  <!-- Header-->
  <header id="header" class="header">
    <div class="top-left">
      <div class="navbar-header">
        <router-link class="navbar-brand" to="/">
          INEB JV
          </router-link>
        <a
        class="navbar-brand hidden"
        href="./"><img width="40" src="@/assets/images/logo.png" alt="Logo">
        </a>
        <a id="menuToggle" class="menutoggle"><i class="fa fa-bars"></i></a>
        <!--
        <div class="float-right">
          <p>
            <a target="_blank" :href="settings_obj.link_meet" title="Ir a meet">
              <i class="fa fa-external-link-square fa-1x"></i>
              Ir
              <img width="80" src="@/assets/images/logo_meet.png" alt="">
            </a>
          </p>
        </div>-->
      </div>
    </div>
    <div class="top-right">
      <div class="header-menu">
        <div class="dropdown">
          <div class="dropdown btn" v-if='$store.state.settings_obj'>
            <p>{{$store.state.settings_obj.student_name}} </p>
          </div>
        </div>
        <div class="dropdown d-none d-sm-block">
          <div class="dropdown btn btn-outline-primary btn-sm"
          style="line-height:30px; !important" >
            <span title="Ciclo, Grado, sección" v-if="settings_obj">
              {{settings_obj.ciclo_name}}, {{settings_obj.grade_name}}
              "{{settings_obj.section_name}}"
            </span>
          </div>
        </div>
        <div class="header-left">
          <div class="dropdown for-message">
              <button class="btn btn-secondary
              dropdown-toggle" type="button"
              id="message" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
                  <i v-if="list_notification.filter( x => !x.read ).length == 0"
                    class="fa fa-bell-o"></i>
                    <i v-if="list_notification.filter( x => !x.read ).length > 0"
                      class="fa fa-bell"></i>
                  <span v-if="list_notification.filter( x => !x.read ).length > 0"
                    class="count bg-primary">1</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="message">
                  <p class="red">Tiene
                    {{list_notification.filter( x => !x.read ).length }}
                  mensajes</p>
                  <a @click.prevent="market_notification(item, index)" class="dropdown-item media"
                  href="#" v-for="(item, index) in list_notification" :key="index">
                    <span class="photo media-left">
                      <i v-if="!item.read" class="fa fa-envelope"></i>
                      <i v-if="item.read" class="fa fa-envelope-open-o"></i>
                    </span>
                    <div class="message media-body">
                      <span class="name float-left"> {{item.title}}</span>
                      <span class="time float-right">{{item.created_at|to_humman_date}}</span>
                      <p>{{item.message}}</p>
                    </div>
                  </a>
                  <router-link to="notifications" class="dropdown-item media">Todas</router-link>
              </div>
          </div>
        </div>
        <div class="user-area dropdown float-right" v-if="settings_obj">
          <a href="#"
          class="dropdown-toggle active"
          data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
            <img v-if="settings_obj.avatar"
            :src="'data:image/png;base64,' + settings_obj.avatar_url"
            class="user-avatar rounded-circle" alt="User Avatar">
            <div class="stat-icon flat-color-3" style="font-size:38px; " v-else>
              <i class="s7 s7-user"></i>
            </div>
          </a>

          <div class="user-menu dropdown-menu">
            <router-link class="nav-link" to="profile">
              <i class="fa fa-user"></i>Perfil
            </router-link>
            <!--
            <router-link class="nav-link" to="/settings">
              <i class="fa fa-cog"></i>Settings
            </router-link>
            -->
            <a class="nav-link" v-if="is_login || !$store.state.settings"
              href="#" @click.prevent="Logout"><i class="fa fa-power-off"></i>Salir
              </a>
          </div>
        </div>
      </div>
    </div>
    <div class="progress " style="height: 3px; margin-left:4.5rem;">
      <div
        class="progress-bar bg-purple"
        role="progressbar"
        style="width: 100"
        :style="'width:' + progress_status+'%;'"
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="100">
        </div>
    </div>
  </header>
</template>
<script>
import moment from 'moment';

export default {
  name: 'Header',
  created() {
    this.$nextTick(() => {
      const items = document.querySelectorAll('ul.nav.navbar-nav li a');
      items.forEach((item) => {
        item.addEventListener('click', () => {
          const windowWidth = window.screen.width;
          if (windowWidth < 760) {
            const isDisplay = document.querySelector('#left-panel').style.display;
            if (isDisplay.length > 0) {
              document.querySelector('#left-panel').style.removeProperty('display');
            }
          }
        });
      });
      document.querySelector('#menuToggle').addEventListener('click', () => {
        const windowWidth = window.screen.width;
        if (windowWidth < 1010) {
          document.querySelector('body').classList.remove('open');
          if (windowWidth < 760) {
            const isDisplay = document.querySelector('#left-panel').style.display;
            if (isDisplay.length === 0) {
              document.querySelector('#left-panel').style.display = 'block';
            } else {
              document.querySelector('#left-panel').style.removeProperty('display');
            }
          } else {
            document.querySelector('#left-panel').classList.toggle('open-menu');
          }
        } else {
          document.querySelector('body').classList.toggle('open');
          document.querySelector('#left-panel').classList.remove('open-menu');
        }
      });
    });
    this.get_notification();
  },
  data() {
    return {
      base: process.env.VUE_APP_BASE_URL,
      url: 'notifications/',
      list_notification: [],
    };
  },
  methods: {
    handlerError: (error) => {
      const result = error.response.data;
      const self = this;
      if (typeof (error.response.data) === 'object') {
        Object.entries(result).forEach(([key, value]) => { window.$swal.fire('Error!', `${key}: ${value}`, 'error'); });
        return false;
      }
      self.$swal.fire('Error!', result, 'error');
      return false;
    },
    Logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      }).catch(() => {
        this.$swal.fire('Error!', 'No fue posible finalizar la sesión.', 'error');
      });
    },
    get_notification() {
      const self = this;
      this.sendRequest({ read: 'False', limit: '5' }).then((data) => {
        self.list_notification = data.data;
      }).catch((error) => {
        const result = error.response.data;
        if (typeof (error.response.data) === 'object') {
          Object.entries(result).forEach(([key, value]) => { self.$swal.fire('Error!', `${key}: ${value}`, 'error'); });
          return false;
        }
        return false;
      });
    },
    market_notification(item, index) {
      if (item.read) { return false; }

      let payload = {};
      payload = item;
      payload.readed = 'True';
      const self = this;
      this.putRequest(payload).then((data) => {
        self.$set(self.list_notification, index, data.data);
      }).catch((error) => {
        const result = error.response.data;
        if (typeof (error.response.data) === 'object') {
          Object.entries(result).forEach(([key, value]) => { self.$swal.fire('Error!', `${key}: ${value}`, 'error'); });
          return false;
        }
        return false;
      });
      return false;
    },
    async sendRequest(payload) {
      const result = await this.$http.get(this.url, { params: payload });
      return result;
    },
    async putRequest(payload) {
      const data = await this.$http.put(`${this.url}${payload.id}/`, payload);
      return data;
    },
  },
  filters: {
    to_humman_date(value) {
      if (!value) return '-----';
      return moment(value, 'YYYY-MM-DD HH:mm:ss', 'es').fromNow();
    },
  },
  computed: {
    is_login() {
      return this.$store.getters.is_login;
    },
    settings_obj() {
      return this.$store.state.settings_obj;
    },
    progress_status() {
      return this.$store.state.progress;
    },
  },
};

</script>
